<script>


import {
  authMethods,
  authFackMethods,
  notificationMethods
} from "@/state/helpers";

import Vue from 'vue';
import {required, email, minLength} from "vuelidate/lib/validators";

// Import HTTP
import HttpRequest from "@/http/HttpRequest"

// Import Util
//import Util from "@/helpers/Utils"

// Import component
import Loading from 'vue-loading-overlay';

// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';

// Init plugin
Vue.use(Loading);

//Common HTTP object for managing request response
let httpRequest = new HttpRequest()

export default {
  data() {
    return {
      user: {name: "", email: "", password: "", auth_type: "", work_space: ""},
      submitted: false,
      regError: null,
      tryingToRegister: false,
      isRegisterError: false,
      registerSuccess: false,
      inputType: "password",
      iconClass: "ri-eye-off-line",
    };
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    }
  },
  validations: {
    user: {
      name: {required},
      email: {required, email},
      password: {required, minLength: minLength(8)},
      work_space: {required, minLength: minLength(3)}
    }
  },
  created() {
    document.body.classList.add("auth-body-bg");
    this.user.email = this.$route.query.email
  },
  mounted() {

  },
  methods: {
    ...authMethods,
    ...authFackMethods,
    ...notificationMethods,
    togglePasswordVisibility() {
      this.inputType = this.inputType === "password" ? "text" : "password";
      this.iconClass =
          this.inputType === "password" ? "ri-eye-off-line" : "ri-eye-line";
    },
    doRegistration() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        let _this = this
        // Init loader
        let loader = this.$loading.show({
          // Optional parameters
          container: this.fullPage ? null : this.$refs.formContainer,
          canCancel: false,
          onCancel: this.onCancel,
          backgroundColor: "#ffffff",
          loader: "dots"
        });

        // Init sweet alert
        let swal = this.$swal
        if (_this.user.work_space.match(/^([a-zA-Z][a-zA-Z\d]{2,15})$/g)) {
          // TODO: Fix auth type value
          // tinyint (0-password, 1-ms, 2-fb, 3-google, 4-...)
          httpRequest.registration(this.user.name, this.user.email, this.user.password, 0, this.user.work_space).then(function (response) {
            loader.hide()
            if (response.success) {
              if (response.message == "User registered successful!") {
                swal("Account creation successful", "Please Login to your account", "success").then(value => {
                  if (value) {
                    _this.$router.push({path: `/login?id=${_this.user.email}`});
                  }
                })
              } else {
                swal("Account creation successful", "Please verify your account", "success").then(value => {
                  if (value) {
                    _this.$router.push({path: `/verify-email?id=${_this.user.email}`});
                  }
                })
              }
            } else {
              swal(response.message);
            }
          })

        } else {
          loader.hide()
          swal('Workspace name can not start with digit, contains 3 to 12 characters without any space and special character');
        }
      }
    }

  }
};
</script>

<template>
  <div class="container-fluid p-0">
    <div class="row no-gutters">
      <div class="col-lg-5 col-xl-4" style="background-color: #f1f5f7">
        <div class="form-page-content p-4 d-flex align-items-center flex-column justify-content-center min-vh-100">

          <div class="text-center">
            <div>
              <a href="/" class="logo">
                <img src="@/assets/images/workany-v-logo.png" height="130" width="180" alt="WorkAny"/>
              </a>
              <h4 class="font-size-18 mt-3 mt-md-4">Get started with WORKANY</h4>
              <p>Supercharge your team productivity</p>
            </div>
          </div>

          <div class="p-2 mt-3 mt-md-4 w-100" style="max-width: 440px">
            <b-alert
                v-model="registerSuccess"
                class="mt-3"
                variant="success"
                dismissible
            >Registration successful.
            </b-alert>

            <b-alert
                v-model="isRegisterError"
                class="mt-3"
                variant="danger"
                dismissible
            >{{ regError }}
            </b-alert>

            <b-alert
                variant="danger"
                class="mt-3"
                v-if="notification.message"
                show
                dismissible
            >{{ notification.message }}
            </b-alert>

            <form class="form-horizontal" @submit.prevent="doRegistration">
              <div class="form-group auth-form-group-custom mb-3">
                <i class="ri-user-2-line auti-custom-input-icon"></i>
                <label for="username">Name</label>
                <input
                    v-model="user.name"
                    type="text"
                    class="form-control"
                    id="username"
                    :class="{ 'is-invalid': submitted && $v.user.name.$error }"
                    placeholder="Enter your full name"
                />
                <div
                    v-if="submitted && !$v.user.name.required"
                    class="invalid-feedback"
                >Name is required.
                </div>
              </div>

              <div class="form-group auth-form-group-custom mb-3">
                <i class="ri-mail-line auti-custom-input-icon"></i>
                <label for="useremail">Email</label>
                <input
                    v-model="user.email"
                    type="email"
                    class="form-control"
                    id="useremail"
                    placeholder="Enter your email"
                    :class="{ 'is-invalid': submitted && $v.user.email.$error }"
                />
                <div v-if="submitted && $v.user.email.$error" class="invalid-feedback">
                  <span v-if="!$v.user.email.required">Email is required.</span>
                  <span v-if="!$v.user.email.email">Please enter valid email.</span>
                </div>
              </div>

              <div class="form-group auth-form-group-custom mb-3">
                <i class="ri-lock-2-line auti-custom-input-icon"></i>
                <label for="userpassword">Password</label>
                <input
                    v-model="user.password"
                    :type="inputType"
                    maxlength="30"
                    class="form-control"
                    id="userpassword"
                    placeholder="Enter your password"
                    :class="{ 'is-invalid': submitted && $v.user.password.$error }"
                />
                <i
                    class="auti-custom-input-icon-i"
                    :class="iconClass"
                    style="width: 15px"
                    @click="togglePasswordVisibility"
                ></i>
                <div v-if="submitted && $v.user.password.$error" class="invalid-feedback">
                  <span v-if="!$v.user.password.required">Password is required</span>
                  <span v-if="user.password && !$v.user.password.minLength">Password must be minimum 8 characters</span>
                </div>
              </div>

              <div class="form-group auth-form-group-custom mb-3">
                <i class="ri-home-4-line auti-custom-input-icon"></i>
                <label for="workspace">Workspace Name</label>
                <input
                    v-model="user.work_space"
                    type="text"
                    maxlength="15"
                    class="form-control"
                    id="workspace"
                    placeholder="Enter your work space name"
                    :class="{ 'is-invalid': submitted && $v.user.work_space.$error }"
                />
                <div v-if="submitted && $v.user.work_space.$error" class="invalid-feedback">
                  <span v-if="!$v.user.work_space.required">Work space name required</span>
                  <span v-if="user.work_space && !$v.user.work_space.minLength">Work space name must be minimum 3 characters</span>
                </div>
              </div>

              <div class="form-group mb-3" v-show="user.work_space.length>2">
                <label for="workspace">Workspace URL</label>
                <b-input-group prepend="https://" append=".workany.io">
                  <input
                      v-model="user.work_space"
                      type="text"
                      readonly
                      class="form-control"
                      placeholder="your work space url"
                      :class="{ 'is-invalid': submitted && $v.user.work_space.$error }"
                  />
                </b-input-group>
              </div>


              <div class="text-center">
                <p class="mb-0">
                  By registering you agree to the WORKANY
                  <a href="https://www.workany.io/terms-and-conditions/" class="text-primary" target="_blank">Terms of
                    Use</a>
                </p>
              </div>

              <div class="mt-3 mt-md-4  text-center">
                <button
                    class="btn btn-primary w-md waves-effect waves-light customs-bg-color"
                    type="submit"
                >LET'S GET STARTED
                </button>
              </div>
            </form>
          </div>


          <div class=" mt-md-4 text-center">
            <p class="mb-0">Already have an account ?</p>
            <p class="mb-0">Please login using your Workspace URL</p>
            <router-link
                tag="a"
                to="/forgot-workspace"
                class="font-weight-medium text-primary"
            >I forget my Workspace URL
            </router-link>
          </div>

          <div class="mt-3 mt-md-4 text-center position-absolute fixed-bottom">
            <div style="border-bottom: 1px solid #ddd "></div>
            <p class="mt-3 mt-md-4 text-center">© 2023 WORKANY. Crafted by <a href="https://w3engineers.com/"
                                                                              class="text-primary" target="_blank">W3
              Engineers Ltd</a>.</p>
          </div>

        </div>
      </div>
      <div class="col-lg-7 col-xl-8 d-none d-lg-block">
        <div class="min-vh-100 py-5 d-flex align-items-center">
          <div class="w-100">
            <h4 class="font-size-28 text-center px-3">Track your team productivity effortlessly</h4>
            <p class="text-center px-3">Reach your goal faster with auto efficiency-booster.</p>
            <div class="d-flex justify-content-center">
              <img src="@/assets/images/bg/home2.jpg" height="100%" width="55%" alt="logo"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.customs-bg-color {
  background-color: #4B1FE5 !important;
}
</style>

